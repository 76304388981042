import { RootState } from '../../types';
import { Account } from '../../../../domain/accounts/Account';

export const getAllAccountTransactions = (state: RootState) => {
	return state.accounts.allAccountTransactions;
};

export const createGetAccountTransactionsSelector = (accountUUID?: Account['uuid']) => {
	return (state: RootState) => {
		if (accountUUID) {
			return state.accounts.allAccountTransactions.filter(
				t => t.accountUUID === accountUUID || t.transferToAccountUUID === accountUUID
			);
		}

		return getAllAccountTransactions(state);
	};
};

export const getTransactions = (state: RootState) => {
	return getAllAccountTransactions(state);
}
