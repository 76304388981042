import React, { useState, useMemo, useRef, useLayoutEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { TableInstance } from 'react-table';
import { Transaction } from '../../../domain/transactions/Transaction';
import { Account } from '../../../domain/accounts/Account';
import TableHeaders from './TableHeaders';
import TableTools from './TableTools/TableTools';
import TableFooter from './TableFooter/TableFooter';
import { VariableSizeList, VariableSizeList as List } from 'react-window';
import useElementResizeHook from '../../common/hooks/useElementResizeHook';
import TableRow, { showDateHeader } from './TableRow/TableRow';
import './transactions-table.less';
import { useSelector } from 'react-redux';
import { isSmallScreen } from '../../store/ui/selectors/window';
import AddEditTransactionRow from '../AddEditTransaction/AddEditTransactionRow/AddEditTransactionRow';
import AddEditTransactionModal from '../AddEditTransaction/AddEditTransactionModal/AddEditTransactionModal';
import useTransactionsTable from "view/accounts/TransactionsTable/useTransactionsTable";
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary';

type Props = {
	transactions: Array<Transaction>;
	account: Account | undefined;
};

export type EditingTransaction = {
	index: number | null;
	uuid: Transaction['uuid'] | null;
};

const emptyStyle = {};

const TransactionsTable = ({ transactions, account }: Props) => {
	const { accountUUID } = useParams<{ accountUUID: Account['uuid']}>();
	const [addingTransaction, setAddingTransaction] = useState(false);
	const [addingTransfer, setAddingTransfer] = useState(false);
	const [editingTransaction, setEditingTransaction] = useState<EditingTransaction>({
		index: null,
		uuid: null,
	});
	const smallScreen = useSelector(isSmallScreen);
	const listRef = useRef<VariableSizeList>(null);
	const resetRowSize = useCallback((index: number) => {
		listRef && listRef.current !== null && listRef.current.resetAfterIndex(index);
	}, []);

	const editTransaction = useMemo(() => {
		return (transactionUUID: Transaction['uuid'], index: number) => {
			setEditingTransaction({
				index: index,
				uuid: transactionUUID,
			});
			resetRowSize(index);
		};
	}, [resetRowSize, setEditingTransaction]);

	const table = useTransactionsTable(transactions, accountUUID);

	const transactionBeingEdited = editingTransaction.index !== null
		? table.rows[editingTransaction.index]?.original
		: undefined

	const [windowParentHeight, setWindowParentHeight] = useState(200);
	const resizeCallback = useCallback((width: number, height: number) => {
		setWindowParentHeight(height);
	}, []);
	const tWindowRef = useElementResizeHook<HTMLDivElement>(resizeCallback);

	const heightRef = useRef<number>(275);
	const heightChangeCallback = useCallback(
		(height: number) => {
			if (heightRef.current === height) {
				return;
			}
			heightRef.current = height;
			// @ts-ignore
			resetRowSize(editingTransaction.index);
		},
		[editingTransaction.index, resetRowSize]
	);

	const getRowSize = useCallback(
		(index: number) => {
			if (smallScreen) {
				return showDateHeader(table.rows, index) ? 84 : 65; // Todo make these consts somewhere and link to css
			}

			// if editing transaction
			if (
				table.rows[index].original.uuid === editingTransaction.uuid &&
				table.rows[index].original.transferToAccountUUID === null
			) {
				return heightRef.current;
			}

			// if editing transfer
			if (
				table.rows[index].original.uuid === editingTransaction.uuid &&
				table.rows[index].original.transferToAccountUUID !== null
			) {
				return 80;
			}

			return 34;
		},
		[table.rows, editingTransaction, smallScreen]
	);

	const onCancel = useCallback(() => {
		setAddingTransaction(false);
		setAddingTransfer(false);
	}, []);

	const onSave = useCallback(() => {
		setAddingTransaction(false);
		setAddingTransfer(false);
	}, []);

	// Default scroll to bottom
	useLayoutEffect(() => {
		if (listRef.current !== null && table.rows.length) {
			listRef.current.scrollToItem(table.rows.length - 1);
			if (smallScreen) {
				resetRowSize(0);
			}
		}
	}, [table.rows.length, smallScreen, resetRowSize]);

	return (
		<div className={'transactions-table'} {...table.getTableProps()}>
			<ErrorBoundary inline={true}>
				<TableTools
					columns={table.headers}
					heading={account ? account.name : 'All Accounts'}
					selectedTransactions={table.selectedFlatRows.map(row => row.original)}
					rows={table.rows}
				/>
			</ErrorBoundary>
			<div className={'transactions-table__wrap'}>
				<TableHeaders headers={table.headers} />
				<div className={'transactions-table__rows'} {...table.getTableBodyProps()}>
					<div className={'transactions-table__list-window-parent'} ref={tWindowRef}>
						<List
							ref={listRef}
							itemSize={getRowSize}
							height={windowParentHeight}
							itemCount={table.rows.length}
							width={'100%'}
							overscanCount={10}
							itemKey={(
								index: number,
								data: { table: TableInstance<Transaction> }
							) => {
								return data.table.rows[index].original.uuid;
							}}
							itemData={{ table }}
							estimatedItemSize={34}
							className={'transactions-table__list-window'}
						>
							{listChildProps => (
								<TableRow
									{...listChildProps}
									editingTransactionUUID={editingTransaction.uuid}
									onEditTransactionSave={() => {
										setEditingTransaction({
											index: null,
											uuid: null,
										});
										// @ts-ignore
										resetRowSize(editingTransaction.index);
									}}
									onEditTransactionCancel={() => {
										setEditingTransaction({
											index: null,
											uuid: null,
										});
										// @ts-ignore
										resetRowSize(editingTransaction.index);
									}}
									onRowHeightChange={heightChangeCallback}
									onTransactionRowClick={editTransaction}
								/>
							)}
						</List>
					</div>
					{(addingTransfer || addingTransaction) && !smallScreen && (
						<AddEditTransactionRow
							onCancel={onCancel}
							onSave={onSave}
							columns={table.visibleColumns}
							key={`add-transaction`}
							style={emptyStyle}
							onHeightChange={heightChangeCallback}
							isTransfer={addingTransfer}
						/>
					)}
					{smallScreen && (
						<AddEditTransactionModal
							isOpen={editingTransaction.index !== null}
							onEdit={() => {
								editingTransaction.index !== null && resetRowSize(editingTransaction.index)
							}}
							onClose={() =>
								setEditingTransaction({
									index: null,
									uuid: null,
								})
							}
							transaction={transactionBeingEdited}
						/>
					)}
				</div>
			</div>
			<TableFooter
				currentAccountUUID={accountUUID}
				rows={table.rows}
				isAddingTransfer={addingTransfer}
				isAddingTransaction={addingTransaction}
				onAddTransaction={() => {
					setAddingTransaction(true);
					setAddingTransfer(false);
				}}
				onAddTransfer={() => {
					setAddingTransaction(false);
					setAddingTransfer(true);
				}}
				onSave={() => setAddingTransaction(false)}
			/>
		</div>
	);
};

TransactionsTable.whyDidYouRender = true;

export default React.memo(TransactionsTable);
