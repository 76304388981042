import React, { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	STATUS_CLEARED,
	STATUS_RECONCILED,
	STATUS_UNCLEARED,
	Transaction
} from '../../../domain/transactions/Transaction';
import './transaction-row.less';
import { Cell, Row } from 'react-table';
import { editTransactions } from '../../store/accounts/actions/transactions';
import setStatus from '../../../domain/transactions/operations/setStatus';
import classNames from 'classnames';
import {Account} from "../../../domain/accounts/Account";
import { ColumnID } from '../TransactionsTable/useTableColumns/useTableColumns';

type Props = {
	row: Row<Transaction>;
	onEdit: (transactionUUID: Transaction['uuid']) => void;
	style: CSSProperties;
	listIndex: number;
};

const TransactionRow = (props: Props) => {
	const dispatch = useDispatch();
	const { accountUUID } = useParams<{ accountUUID: Account['uuid']}>();
	const transaction = props.row.original;

	const toggleTransactionStatus = () => {
		if (transaction.status !== STATUS_RECONCILED) {
			const toggledStatus =
				transaction.status === STATUS_UNCLEARED ? STATUS_CLEARED : STATUS_UNCLEARED;
			return dispatch(editTransactions(setStatus([transaction], toggledStatus, accountUUID)));
		}
	};

	const getOnClick = (cell: Cell<Transaction>) => {
		if (cell.column.id === 'selection') {
			return () => {};
		}

		if (cell.column.id === 'status') {
			return () => toggleTransactionStatus();
		}

		return () => {
			console.log(transaction);
			props.onEdit(transaction.uuid);
		};
	};

	const rowClasses = classNames({
		'transaction-row': true,
		'transaction-row--striped': props.listIndex % 2,
	});

	return (
		<div className={rowClasses} {...props.row.getRowProps()} style={props.style}>
			<div className={'transaction-row__main-row'}>
				{props.row.cells.map(cell => {
					const cellProps = cell.getCellProps();
					cellProps.style = Object.assign({}, cellProps.style, {
						width: cell.column.width,
						maxWidth: cell.column.maxWidth,
						minWidth: cell.column.minWidth,
					});

					if (cell.column.id === ColumnID.COL_ID_TAGS) {
						return <div className={'transaction-row__cell'} {...cellProps}>
							<span className={'transaction-row__uneditable'}>
								{cell.render('Cell')}
							</span>
						</div>
					}

					return (
						<div className={'transaction-row__cell'} {...cellProps}>
							<span
								className={'transaction-row__editable'}
								onClick={getOnClick(cell)}
							>
								{cell.render('Cell')}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default React.memo(TransactionRow);
