import { SubCategoryNameMap } from './subCategories';
import { AccountNameMap } from './accounts';
import { getUUID } from '../../../index';
import {
	INCOME_CATEGORY_ID_NEXT_MONTH,
	INCOME_CATEGORY_ID_THIS_MONTH,
	SubCategory,
	TRANSFER_CATEGORY_ID,
} from '../../../categories';
import { getStatusFromRow } from './util';
import { Transaction } from '../../../transactions/Transaction';
import createTransaction from '../../../transactions/operations/createTransaction';
import { Profile } from '../../../profiles';
import { Account } from '../../../accounts/Account';
import {RegisterRow} from "./index";

const PAYEE_TRANSFER_REGEX = RegExp(/(Transfer : .*)/);

export const getTransactions = (
	registerRows: RegisterRow[],
	subCategoriesMap: SubCategoryNameMap,
	accountsMap: AccountNameMap,
	profile: Profile
) => {
	return registerRows
		.map((row): Transaction | null => {
			if (isRegisterRowTransfer(row)) {
				return getTransfer(row, registerRows, accountsMap, subCategoriesMap, profile);
			}

			if (row.masterCategory === 'Income' || row.masterCategory === 'Inflow') {
				return getIncomeTransaction(row, accountsMap, profile);
			}

			return {
				uuid: getUUID(),
				date: row.date,
				categoryId: row.category === '' ? [] : [getCategory(row, subCategoriesMap).uuid],
				accountUUID: getAccount(row.account, accountsMap).uuid,
				transferToAccountUUID: null,
				transferToAccountStatus: null,
				profileUUID: profile.uuid,
				note: row.memo,
				inflow: row.inflow,
				outflow: row.outflow,
				payee: row.payee,
				splits: [],
				status: getStatusFromRow(row),
				tags: []
			};
		})
		.filter((transaction): transaction is Transaction => transaction !== null);
};

const isRegisterRowTransfer = (row: RegisterRow) => {
	return PAYEE_TRANSFER_REGEX.test(row.payee);
};

const getAccount = (accountName: string, accountsMap: AccountNameMap) => {
	const account = accountsMap.get(accountName);

	if (!account) {
		throw new Error(`Cannot find account with name: ${accountName}`);
	}

	return account;
};

const getCategory = (row: RegisterRow, subCategoriesMap: SubCategoryNameMap): SubCategory => {
	const subCategoryName = row.category.split(':').pop()?.trim();
	const category = subCategoryName ? subCategoriesMap.get(subCategoryName): null;

	if (!category) {
		throw new Error(`Cannot find sub category with name: ${subCategoryName}`);
	}

	return category;
};

const getTransfer = (
	row: RegisterRow,
	registerRows: RegisterRow[],
	accountsMap: AccountNameMap,
	subCategoriesMap: SubCategoryNameMap,
	profile: Profile
) => {
	const found = PAYEE_TRANSFER_REGEX.exec(row.payee);
	if (!found) {
		throw new Error(`Could not determine transfer account`);
	}

	// This is an inflow transfer from another account. We don't need to create
	// a transaction for this otherwise the transfer will be duplicated.
	if (row.inflow > 0) {
		return null;
	}

	const linkedTransfer = findLinkedTransferRow(row, registerRows, accountsMap);
	const accountName = found[0].split(/:(.*)/s)[1].trim();
	const account = getAccount(accountName, accountsMap);
	const categories = getCategoriesForTransfer(row, linkedTransfer, subCategoriesMap);

	return createTransaction({
		date: row.date,
		categoryId: categories.filter(catUUID => catUUID !== null) as SubCategory['uuid'][],
		accountUUID: getAccount(row.account, accountsMap).uuid,
		transferToAccountUUID: account.uuid,
		transferToAccountStatus: getStatusFromRow(linkedTransfer || row),
		profileUUID: profile.uuid,
		note: row.memo,
		inflow: row.inflow,
		outflow: row.outflow,
		status: getStatusFromRow(row),
	});
};

const getIncomeTransaction = (
	row: RegisterRow,
	accountsMap: AccountNameMap,
	profile: Profile
) => {
	const subCategoryName = row.category.split(':').pop();
	let categoryID: string|null = null;
	switch(subCategoryName) {
		case 'Available this month':
			categoryID = INCOME_CATEGORY_ID_THIS_MONTH;
			break;
		case 'Available next month':
			categoryID = INCOME_CATEGORY_ID_NEXT_MONTH;
			break;
		case 'Ready to Assign':
			categoryID = INCOME_CATEGORY_ID_THIS_MONTH;
			break;
		default:
			categoryID = INCOME_CATEGORY_ID_THIS_MONTH;
	}

	return createTransaction({
		date: row.date,
		categoryId: [categoryID],
		accountUUID: getAccount(row.account, accountsMap).uuid,
		profileUUID: profile.uuid,
		note: row.memo,
		inflow: row.inflow,
		outflow: row.outflow,
		payee: row.payee,
		status: getStatusFromRow(row),
	});
};

const getCategoriesForTransfer = (
	row: RegisterRow,
	linkedRow: RegisterRow|undefined,
	subCategoriesMap: SubCategoryNameMap
): SubCategory['uuid'][] => {
	if (row.category !== '') {
		return [TRANSFER_CATEGORY_ID, getCategory(row, subCategoriesMap).uuid];
	}

	if (linkedRow && linkedRow.category !== '') {
		return [TRANSFER_CATEGORY_ID, getCategory(linkedRow, subCategoriesMap).uuid];
	}

	return [TRANSFER_CATEGORY_ID];
};

const getTransferToAccount = (row: RegisterRow, accountsMap: AccountNameMap): Account | null => {
	if (!isRegisterRowTransfer(row)) {
		return null;
	}

	const found = PAYEE_TRANSFER_REGEX.exec(row.payee);
	if (!found) {
		return null;
	}
	const accountName = found[0].split(/:(.*)/s)[1].trim();
	return getAccount(accountName, accountsMap);
};

const findLinkedTransferRow = (
	row: RegisterRow,
	registerRows: RegisterRow[],
	accountsMap: AccountNameMap
): RegisterRow | undefined => {
	if (!isRegisterRowTransfer(row)) {
		return undefined;
	}

	const transferredFromAccount = getAccount(row.account, accountsMap);
	const transferredToAccount = getTransferToAccount(row, accountsMap);

	if (!transferredFromAccount || !transferredToAccount) {
		return undefined;
	}

	return registerRows.find((findRow: RegisterRow) => {
		if (!isRegisterRowTransfer(findRow)) {
			return false;
		}

		const sameDate = findRow.date.isEqual(row.date);
		const sameAmounts = row.outflow === findRow.inflow;
		const sameMemo = row.memo === findRow.memo;
		const accountsMatch =
			transferredToAccount.uuid === getAccount(findRow.account, accountsMap).uuid;

		return sameDate && sameAmounts && sameMemo && accountsMatch;
	});
};
