import React from 'react';
import Modal from '../../common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { getAllMasterCategories, getHiddenCategories } from '../../store/categories/selectors';
import { MasterCategory, SubCategory } from '../../../domain/categories';
import { unHideCategory } from '../../store/categories/actions/subCategories';
import { AppDispatch } from '../../store/types';
import { unHideMasterCategory } from '../../store/categories/actions/masterCategories';
import {SimpleButton} from "../../common/Button";

type Props = {
	modalOpen: boolean;
	onModalClose: () => void;
};

const UnhideCategoriesModal = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const hiddenCategories = useSelector(getHiddenCategories);
	const masterCategories = useSelector(getAllMasterCategories);
	const hiddenCategoriesGrouped = groupBy(hiddenCategories, 'masterCategoryId') as {
		[key: string]: SubCategory[];
	};
	return (
		<Modal open={props.modalOpen} onClose={props.onModalClose} title={'Hidden Categories'}>
			<ul>
				{map(
					hiddenCategoriesGrouped,
					(subCategories: SubCategory[], masterCategoryId: MasterCategory['uuid']) => {
						const masterCategory = masterCategories.get(masterCategoryId);
						if (!masterCategory) {
							throw new Error("Can't find master category");
						}
						return (
							<li key={masterCategory.uuid}>
								{masterCategory.name}{' '}
								{masterCategory.hidden && (
									<SimpleButton
										onClick={() =>
											dispatch(unHideMasterCategory(masterCategory.uuid))
										}
									>
										Unhide
									</SimpleButton>
								)}
								<ul>
									{subCategories.map(subCat => (
										<UnhideSubCategoryRow
											key={subCat.uuid}
											category={subCat}
											unHideCategory={(categoryUUID: SubCategory['uuid']) =>
												dispatch(unHideCategory(categoryUUID))
											}
										/>
									))}
								</ul>
							</li>
						);
					}
				)}
			</ul>
		</Modal>
	);
};

type UnhideSubCategoryRowProps = {
	category: SubCategory;
	unHideCategory: (categoryUUID: SubCategory['uuid']) => void;
};
const UnhideSubCategoryRow = (props: UnhideSubCategoryRowProps) => {
	return (
		<li>
			{props.category.name}{' '}
			<SimpleButton
				onClick={() => {
					props.unHideCategory(props.category.uuid);
				}}
			>
				Unhide
			</SimpleButton>
		</li>
	);
};

export default UnhideCategoriesModal;
