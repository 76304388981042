import React, { useEffect, useState } from 'react';
import { AdvancedModal } from '../../common/Modal';
import { Button } from '../../common/Button/Button';
import { Icon } from 'semantic-ui-react';
import resetAllCategoryOrder from '../../../domain/categories/operations/resetAllCategoryOrder';
import useActiveProfile from '../../store/profiles/hooks/useActiveProfile';
import appDb from "../../../infrastructure/persistence/indexed-db";

const Troubleshooter = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const profile = useActiveProfile();
	useEffect(() => {
		// @ts-ignore
		window.troubleshooter = {
			open: () => setIsModalOpen(true),
			appDb: appDb
		};

		return () => {
			// @ts-ignore
			delete window.troubleshooter;
		};
	}, []);

	return (
		<AdvancedModal
			open={isModalOpen}
			actions={
				<>
					<Button onClick={() => setIsModalOpen(false)} label={'Close'}>
						<Icon name="remove" /> Close
					</Button>
				</>
			}
			title={'Troubleshooter'}
			onClose={() => setIsModalOpen(false)}
		>
			{profile && (
				<Button
					onClick={() =>
						resetAllCategoryOrder(profile.uuid).then(() =>
							console.log('Done re-ordering categories')
						)
					}
					label={'Reset Category Order'}
				/>
			)}
		</AdvancedModal>
	);
};

export default Troubleshooter;
