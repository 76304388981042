import Dexie from 'dexie';
import 'dexie-observable';
import 'dexie-syncable';
import { UUID } from '../../../domain';
import { IDBTransaction } from './transactions/types';
import { MasterCategory, SubCategory } from '../../../domain/categories';
import { Account } from '../../../domain/accounts/Account';
import { MasterCategorySortOrder, SubCategorySortOrder } from './categories/types';
import {CategoryBudget, MonthBudget} from '../../../domain/budget';
import { Profile } from '../../../domain/profiles';
import {registerWebRTCSync} from "../WebRTCSyncProtocol";
import { TransactionTag } from '../../../domain/transactions/TransactionTag';

export const VERSION_1 = 1;
export const VERSION_4 = 4;

export const CURRENT_VERSION = VERSION_4;

export class AppDB extends Dexie {
	accounts: Dexie.Table<Account, UUID>;
	profiles: Dexie.Table<Profile, UUID>;
	transactions: Dexie.Table<IDBTransaction, UUID>;
	transactionTags: Dexie.Table<TransactionTag, UUID>;
	categoryBudgets: Dexie.Table<CategoryBudget, UUID>;
	subCategories: Dexie.Table<SubCategory, UUID>;
	masterCategories: Dexie.Table<MasterCategory, UUID>;
	masterCategoryOrder: Dexie.Table<MasterCategorySortOrder, UUID>;
	subCategoryOrder: Dexie.Table<SubCategorySortOrder, UUID>;
	monthBudgets: Dexie.Table<MonthBudget, UUID>;

	constructor() {
		super('AppDB');

		// Define tables and indexes
		this.version(VERSION_4).stores({
			profiles: 'uuid',
			accounts: 'uuid, profileUUID',
			transactions:
				'uuid, profileUUID, [profileUUID+date.year+date.month+categoryId], transferToAccountUUID, accountUUID, *monthCategories, [accountUUID+status], [transferToAccountUUID+transferToAccountStatus], *tags',
			transactionTags: 'uuid, profileUUID',
			categoryBudgets: '[profileUUID+year+month+categoryId], categoryId, profileUUID',
			subCategories: 'uuid, profileUUID',
			masterCategories: 'uuid, profileUUID',
			masterCategoryOrder: 'uuid, order',
			subCategoryOrder: 'uuid',
			monthBudgets: 'uuid, &[profileUUID+month.year+month.month]',
		}).upgrade(tx => {
			return tx.table("transactions").toCollection().modify(t => {
				t.tags = []
			});
		});

		// The following lines are needed for it to work across typescript using babel-preset-typescript:
		this.profiles = this.table('profiles');
		this.accounts = this.table('accounts');
		this.transactions = this.table('transactions');
		this.transactionTags = this.table('transactionTags');
		this.categoryBudgets = this.table('categoryBudgets');
		this.subCategories = this.table('subCategories');
		this.masterCategories = this.table('masterCategories');
		this.masterCategoryOrder = this.table('masterCategoryOrder');
		this.subCategoryOrder = this.table('subCategoryOrder');
		this.monthBudgets = this.table('monthBudgets');
	}
}

registerWebRTCSync();
// Instantiate DB
const appDb = new AppDB();
export default appDb;
